.ProseMirror {
  .selectedCell {
    position: relative;
    &:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: var(--color-secondary);
      opacity: 0.2;
      pointer-events: none;
    }
  }
}
