:root {
  --color-primary: #065F27; /*'#C3C7EA' */
  --color-green: #84D491;
  --color-dark-green: #2C4334;
  --color-grey: #B0B0B0;
  --color-dark-grey: #505050;
  --color-link: #6E9DE4;
  --color-secondary: #0C8CE9; /* '#987DE4' */
  --color-light-grey: #EFEFEF;
  --color-faded-yellow: #F7F0CC;
  --color-yellow: #F8E580;
  --color-semidark-grey: #808080;

}

html,
body {
  overflow-y: overlay;
  padding: 0;
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;*/
  font-family: Inter, Droid Sans, -sans-serif;
  font-size: 15px;
  text-underline-offset: 4px;
}

body::-webkit-scrollbar {
  background: none;
  width: 7px;
}

body::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 20px;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a.no-default-styles {
  color: inherit;
  text-decoration: none;
}


* {
  box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .focus-outline {
    @apply focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-sky-300 focus-visible:ring-offset-transparent; 
  }

  .darken {
    @apply bg-grey/30 rounded-lg transition-colors duration-200;
  }
  .slightly-darken {
    @apply bg-grey/20 transition-colors duration-200;
  }

  .invalid-input {
    @apply invalid:focus-visible:outline-transparent invalid:border-red-500 invalid:ring-red-500;
  }

  .styled-table-cell {
    @apply border px-0.5 py-0.2;
  }
}


/* TODO: on 3xl screen set font-size to 17px and on smaller to 15px */
@screen 4xl {
  body {
    font-size: 17px;
  }
}

/* Fixes tailwind max-width: 100% meme */
img, video {
  max-width: unset;
  height: unset;
}

